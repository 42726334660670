import {useState} from "react";
import {
  getLogoutUrl,
  getMainProgramsUrl,
  getMainProgramTemplatesUrl,
  getWorkProgramsElementaryUrl, getWorkProgramsGeneralUrl, getWorkProgramsSecondaryUrl
} from "services/navigation";
import {makeKeyGenerator} from "shared/helpers/utils/key-generator";
import {useAuth} from "services/auth";
import {AppMainProgramIcon} from "shared/UI/UIKit/icons/MainProgram";
import {AppSettingsIcon} from "shared/UI/UIKit/icons/Settings";
import {useUserProfileRole} from "common/use-user-profile-role";
import {SidebarMenuHelpers} from "../Sidebar";

const LOGOUT_URL = getLogoutUrl();
const MAIN_PROGRAMS_URL = getMainProgramsUrl();
const MAIN_PROGRAM_TEMPLATES_URL = getMainProgramTemplatesUrl();
const WORK_PROGRAMS_ELEMENTARY_URL = getWorkProgramsElementaryUrl();
const WORK_PROGRAMS_GENERAL_URL = getWorkProgramsGeneralUrl();
const WORK_PROGRAMS_SECONDARY_URL = getWorkProgramsSecondaryUrl();

export const useSidebar = (visible: boolean) => {
  const {getNextKey} = makeKeyGenerator();
  const [openedKeys, setOpenedKeys] = useState(["submenu"]);
  const {isAdmin} = useUserProfileRole();
  const {logout} = useAuth();

  const getSidebarMenuItems = () => {
    if (!visible) {
      return [];
    }

    const templateRoute = SidebarMenuHelpers.getLinkItem({
      key: getNextKey(),
      label: "Шаблоны ООП",
      icon: <AppSettingsIcon/>,
      linkProps: {
        to: MAIN_PROGRAM_TEMPLATES_URL,
      },
    });

    const workProgramsRoute = SidebarMenuHelpers.getSubMenuItem({
      key: "submenu",
      label: "Рабочие программы",
      children: [
        SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: "НОО",
          linkProps: {
            to: WORK_PROGRAMS_ELEMENTARY_URL,
          },
        }),
        SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: "ООО",
          linkProps: {
            to: WORK_PROGRAMS_GENERAL_URL,
          },
        }),
        SidebarMenuHelpers.getLinkItem({
          key: getNextKey(),
          label: "СОО",
          linkProps: {
            to: WORK_PROGRAMS_SECONDARY_URL,
          },
        }),
      ]
    });

    const items = [
      SidebarMenuHelpers.getLinkItem({
        key: getNextKey(),
        label: "Конструктор ООП",
        icon: <AppMainProgramIcon/>,
        linkProps: {
          to: MAIN_PROGRAMS_URL,
        },
      }),
      workProgramsRoute,
      SidebarMenuHelpers.getLinkItem({
        key: getNextKey(),
        label: "Выход",
        linkProps: {
          to: LOGOUT_URL,
        },
        onClick: logout
      }),
    ];

    if (isAdmin) {
      items.splice(1, 0, templateRoute);
    }

    return items;
  };

  return {
    sidebarMenuItems: getSidebarMenuItems(),
    openedKeys,
    setOpenedKeys,
  };
};
