import {AppButton, AppIconButton} from "shared/UI/UIKit/Button";
import {AppArrowRightIcon} from "shared/UI/UIKit/icons/Arrows";
import {AppFlex} from "shared/UI/UIKit/Flex";
import styles from "./styles.module.scss";

export type SaveButtonProps = {
  click: () => void;
  showDiff: () => void;
  showDiffLoading: boolean;
  showDiffVisible: boolean;
}

export type NavigationButtonsProps = {
  prev: () => void;
  prevDisabled: boolean;
  nextDisabled: boolean;
  next: () => void;
  hideDiff: () => void;
}

export const SaveButton = (props: SaveButtonProps) => {
  return (
    <div className={styles["sticky-wrap"]}>
      <div className={styles["sticky-button"]}>
        <AppFlex alignItems="center">
          <AppButton onClick={props.click} className={styles.button}>
            Сохранить
          </AppButton>
          {
            props.showDiffVisible && (
              <AppButton onClick={props.showDiff} className={styles.button} loading={props.showDiffLoading}>
                Показать изменения
              </AppButton>
            )
          }
        </AppFlex>
      </div>
    </div>
  );
};

export const NavigationButtons = (props: NavigationButtonsProps) => {
  return (
    <div className={styles["sticky-wrap"]}>
      <div className={styles["sticky-button"]}>
        <AppFlex alignItems="center">
          <AppButton onClick={props.hideDiff} className={styles.button}>
            Показать оригинал
          </AppButton>
          <AppIconButton type="primary" className={styles.button} onClick={props.prev} disabled={props.prevDisabled}>
            <AppArrowRightIcon className={styles.prevIcon}/>
          </AppIconButton>
          <AppIconButton type="primary" className={styles.button} onClick={props.next} disabled={props.nextDisabled}>
            <AppArrowRightIcon className={styles.nextIcon}/>
          </AppIconButton>
        </AppFlex>
      </div>
    </div>
  );
};
